import React from 'react';
import { useRef, useEffect, useCallback } from 'react';

import config from '../config';

function useTheme () {
  const theme = useRef(null);

  const mapTheme = useCallback(
    function handleMapTheme (config = {}) {
      return {
        '--color-primary'          : config['Theme-Color-Primary'] || '',
        '--background-primary'     : config['Theme-Color-Primary'] || '',

        '--color-secondary'        : config['Theme-Color-Secondary'] || '',
        '--background-sec'         : config['Theme-Color-Secondary'] || '',

        '--background-nav'         : config['Theme-Color-Navbar'] || config['Theme-Color-Primary'] || '',

        '--background-primary-button' : config['Theme-Button-Primary'] || '',
        '--color-primary-button'      : config['Theme-Button-Primary-Text'] || ''
      };
    },
    []
  );

  const setTheme = useCallback(
    function handleApplyTheme (config) {
      const root        = document.documentElement;
      const mappedTheme = mapTheme(config);

      theme.current = mappedTheme;

      Object
        .keys(mappedTheme)
        .forEach((key) => { root.style.setProperty(key, mappedTheme[key]); });
    }, 
    [ mapTheme ]
  );

  useEffect(
    function handleSetTheme () {
      setTheme(config.theme);
    }, [ setTheme ]
  )

  return null
}

export default useTheme;
